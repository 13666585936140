<template>
  <div>
    <EditTemplateDialog
      v-model="isOpenDialog"
      title="งานจ้างเหมาทำการซ่อมสะพานและโครงสร้าง "
      :is-add="isAdd"
      :isUpdateLoading="isLoading"
      :readonly="readonly"
      @delete="deleteMaintenance()"
      @clear="clearForm()"
      @close="close()"
      @confirm="confirm()"
    >
      <div class="pa-4">
        <v-form
          ref="form"
          lazy-validation
          class="pt-0"
          enctype="multipart/form-data"
        >
          <div>
            <v-row align="center">
              <v-col
                v-for="(form, formindex) in formEditField"
                :key="formindex"
                :sm="form.col"
                :cols="form.colxs ? form.colxs : '12'"
              >
                <v-row align="center">
                  <v-col
                    :class="$vuetify.breakpoint.xs && 'pb-0'"
                    :sm="form.coltext"
                    cols="12"
                    class="form-text"
                  >
                    <span v-text="form.name"></span>
                    <span v-if="form.isrequired" class="red--text pl-1">*</span>
                  </v-col>
                  <v-col
                    :class="$vuetify.breakpoint.xs && 'pt-0'"
                    :sm="form.colfield"
                    cols="12"
                  >
                    <v-text-field
                      v-if="form.model === 'contract_no'"
                      v-model="editForm[form.model]"
                      class="right-input"
                      type="number"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="0"
                      :disabled="isLoading"
                      :readonly="readonly"
                      :rules="form.isrequired ? [rules.required] : []"
                    >
                      <template v-slot:prepend-inner>
                        <div
                          class="d-flex align-center justify-center"
                          style="
                            width: 50px;
                            height: 40px;
                            margin: -8px 4px 0 -12px;
                            background-color: #e1e1e1;
                            border-radius: 4px 0 0 4px;
                          "
                        >
                          <span>ขทพ.</span>
                        </div>
                      </template>
                    </v-text-field>
                    <v-text-field
                      v-else-if="form.type === 'text'"
                      v-model="editForm[form.model]"
                      outlined
                      dense
                      hide-details="auto"
                      :disabled="isLoading"
                      :readonly="readonly"
                      :rules="form.isrequired ? [rules.required] : []"
                    ></v-text-field>
                    <v-text-field
                      v-else-if="form.type === 'number'"
                      v-model="editForm[form.model]"
                      class="right-input"
                      type="number"
                      :placeholder="form.placeholder ? form.placeholder : '0'"
                      min="0"
                      outlined
                      dense
                      hide-details="auto"
                      :disabled="isLoading"
                      :readonly="readonly"
                      :rules="form.isrequired ? [rules.required] : []"
                    ></v-text-field>
                    <DatePicker
                      v-else-if="form.type === 'date'"
                      v-model="editForm[form.model]"
                      :readonly="readonly"
                      :disabled="isLoading"
                      :rules="form.isrequired ? [rules.required] : []"
                      class="mt-1"
                      hide-details="auto"
                    />
                    <v-textarea
                      v-else-if="form.type === 'textarea'"
                      v-model="editForm[form.model]"
                      type="number"
                      outlined
                      dense
                      hide-details="auto"
                      :disabled="isLoading"
                      :readonly="readonly"
                      :rules="form.isrequired ? [rules.required] : []"
                    ></v-textarea>
                    <v-select
                      v-else-if="form.type === 'select'"
                      v-model="editForm[form.model]"
                      placeholder="เลือก"
                      outlined
                      dense
                      hide-details="auto"
                      :items="itemLists[form.select.name]"
                      :item-text="form.select.text"
                      :item-value="form.select.value"
                      :clearable="!readonly"
                      :disabled="isLoading"
                      :readonly="readonly"
                      :rules="form.isrequired ? [rules.required] : []"
                    ></v-select>
                    <v-autocomplete
                      v-else-if="form.type === 'autocomplete'"
                      v-model="editForm[form.model]"
                      outlined
                      dense
                      multiple
                      :clearable="!readonly"
                      hide-details="auto"
                      :readonly="readonly"
                      :rules="[rules.requiredAutocomplete]"
                      :items="itemLists[form.autocomplete.name]"
                      :item-text="form.autocomplete.text"
                      :item-value="form.autocomplete.value"
                      :disabled="isLoading"
                      placeholder="เลือก"
                    >
                      <template v-slot:selection="{ item, index }">
                        <div v-if="index === 0">
                          <span
                            >{{ item.name }}
                            {{
                              item.direction ? `(${item.direction})` : ""
                            }}</span
                          >
                        </div>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ editForm[form.model].length - 1 }} รายการ)
                        </span>
                      </template>
                      <template v-slot:item="{ item }">
                        <div>
                          {{ item.name }}
                          {{ item.direction ? `(${item.direction})` : "" }}
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-btn
              v-if="!readonly"
              class="mt-4 elevation-0 blue white--text"
              @click="addListForm"
            >
              <v-icon>mdi-plus</v-icon>
              เพิ่มรายการ
            </v-btn>
          </div>
          <div
            v-for="(item, itemKey) in editFormDetail"
            :key="itemKey"
            style="background: #fffaea"
            class="pa-2 my-4"
          >
            <!-- <v-divider class="my-4"></v-divider> -->
            <v-row align="center">
              <v-col
                v-for="(form, formDetaiFieldlIndex) in formDetailField"
                :key="formDetaiFieldlIndex"
                :sm="form.col"
                cols="12"
              >
                <v-row align="center">
                  <v-col
                    :class="$vuetify.breakpoint.xs && 'pb-0'"
                    :sm="form.coltext"
                    cols="12"
                    class="form-text"
                  >
                    <span v-text="form.name"></span>
                    <span v-if="form.isrequired" class="red--text pl-1">*</span>
                  </v-col>
                  <v-col :class="$vuetify.breakpoint.xs && 'pt-0'">
                    <v-card
                      v-if="form.model === 'percent_task'"
                      style="background-color: #e1e1e1"
                      class="elevation-0 d-flex justify-end pa-2 pr-4"
                      outlined
                    >
                      <div>{{ percentTask[itemKey] }}</div>
                    </v-card>
                    <v-text-field
                      v-else-if="form.type === 'text'"
                      v-model="editFormDetail[itemKey][form.model]"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                      :disabled="isLoading"
                      :readonly="readonly"
                      :rules="form.isrequired ? [rules.required] : []"
                    ></v-text-field>
                    <v-text-field
                      v-else-if="form.type === 'number'"
                      v-model="editFormDetail[itemKey][form.model]"
                      class="right-input"
                      type="number"
                      placeholder="0"
                      min="0"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                      :disabled="isLoading"
                      :readonly="readonly"
                      :rules="form.isrequired ? [rules.required] : []"
                    ></v-text-field>
                    <v-textarea
                      v-else-if="form.type === 'textarea'"
                      v-model="editFormDetail[itemKey][form.model]"
                      type="number"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                      :disabled="isLoading"
                      :readonly="readonly"
                      :rules="form.isrequired ? [rules.required] : []"
                    ></v-textarea>
                    <v-select
                      v-else-if="form.type === 'select'"
                      v-model="editFormDetail[itemKey][form.model]"
                      placeholder="เลือก"
                      outlined
                      dense
                      hide-details="auto"
                      :items="itemLists[form.select.name]"
                      :item-text="form.select.text"
                      :item-value="form.select.value"
                      :clearable="!readonly"
                      background-color="white"
                      :disabled="isLoading"
                      :readonly="readonly"
                      :rules="form.isrequired ? [rules.required] : []"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div v-if="!readonly" class="d-flex justify-end mt-4">
              <v-btn
                color="primary"
                outlined
                :disabled="isLoading"
                @click="removeEditFormDetail(itemKey)"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
                ลบรายการนี้
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>
    </EditTemplateDialog>
    <ConfirmDialog
      v-model="isConfirmEditDialog"
      :dialog-title="'ยืนยันการแก้ไขข้อมูล ?'"
      @confirm="edit()"
      @cancel="closeConfirm()"
    />
    <ConfirmDialog
      v-model="isConfirmRemoveEditFormDetailDialog"
      :dialog-title="'ยืนยันการลบรายการ ?'"
      @confirm="confirmRemoveEditFormDetail()"
      @cancel="closeListConfirm()"
    />
  </div>
</template>

<script>
import EditTemplateDialog from './EditTemplateDialog.vue'
import DatePicker from '@/components/DatePicker.vue'
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
export default {
  components: {
    EditTemplateDialog,
    DatePicker,
    ConfirmDialog
  },
  props: {
    value: Boolean,
    maintenanceInfo: Object,
    isAdd: Boolean,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.isOpenDialog = newVal
      if (newVal) {
        this.editFormDetail = []
        if (!this.isAdd) {
          this.editForm = { ...this.maintenanceInfo }
          this.getMaintenanceContractDetailList()
        } else {
          if (this.$refs.form) this.$refs.form.reset()
          this.editForm = {}
        }
      }
    },
    'editForm.road_id' (newVal, oldVal) {
      if (newVal) {
        // this.editForm.control = null
        this.getSectionPart(newVal)
      } else {
        delete this.editForm.control
        this.itemLists.control = []
      }
    }
  },
  data: () => ({
    isOpenDialog: false,
    isConfirmRemoveEditFormDetailDialog: false,
    formEditField: [
      {
        name: 'ปีงบประมาณ',
        model: 'budget_year',
        placeholder: (new Date().getFullYear() + 543).toString(),
        col: 6,
        coltext: 4,
        colfield: 8,
        colxs: 6,
        type: 'number',
        isrequired: true
      },
      {
        name: 'เลขที่สัญญา',
        model: 'contract_no',
        col: 6,
        coltext: 4,
        colfield: 8,
        colxs: 6,
        type: 'number',
        isrequired: true
      },
      {
        name: 'ผู้รับจ้าง',
        model: 'company_contract',
        col: 12,
        coltext: 2,
        colfield: 10,
        type: 'text',
        isrequired: true
      },
      {
        name: 'เริ่มต้นสัญญา',
        model: 'contract_date_start',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'date',
        isrequired: true
      },
      {
        name: 'สิ้นสุดสัญญา',
        model: 'contract_date_end',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'date',
        isrequired: true
      },
      {
        name: 'ระยะเวลาทำงาน (วัน)',
        model: 'work_day',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'ค่าก่อสร้างตามสัญญา (บาท)',
        model: 'price_work_contract',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'หมายเลขทางหลวง',
        model: 'road_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        select: {
          name: 'road',
          text: 'label',
          value: 'road_id'
        },
        isrequired: true
      },
      {
        name: 'ตอนควบคุม',
        model: 'section_part_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        select: {
          name: 'control',
          text: 'label',
          value: 'section_part_id'
        },
        isrequired: true
      },
      {
        name: 'สะพาน',
        model: 'bridge_ids',
        col: 12,
        coltext: 2,
        colfield: 10,
        type: 'autocomplete',
        autocomplete: {
          name: 'bridgeList',
          text: 'name',
          value: 'id'
        },
        isrequired: true
      }
    ],
    formDetailField: [
      {
        name: 'รายการ',
        model: 'name',
        col: 12,
        coltext: 2,
        type: 'text',
        isrequired: true
      },
      {
        name: 'หน่วย',
        model: 'ref_maintenance_unit_id',
        col: 6,
        coltext: 4,
        type: 'select',
        select: {
          name: 'maintenance_unit',
          text: 'name',
          value: 'id'
        },
        isrequired: true
      },
      {
        name: 'ราคาต่อหน่วย (บาท)',
        model: 'price_per_unit',
        col: 6,
        coltext: 4,
        type: 'number',
        isrequired: true
      },
      {
        name: 'ปริมาณงานตามสัญญา',
        model: 'amount_task_contract',
        col: 6,
        coltext: 4,
        type: 'number',
        isrequired: true
      },
      {
        name: 'จำนวนเงินตามสัญญา (บาท)',
        model: 'price_contract',
        col: 6,
        coltext: 4,
        type: 'number',
        isrequired: true
      },
      {
        name: 'ปริมาณงานในสนาม',
        model: 'amount_task_field',
        col: 6,
        coltext: 4,
        type: 'number',
        isrequired: true
      },
      {
        name: 'จำนวนเงินปริมาณงานในสนาม',
        model: 'price_field',
        col: 6,
        coltext: 4,
        type: 'number',
        isrequired: true
      },
      {
        name: 'งานที่ทำได้ (%)',
        model: 'percent_task',
        col: 6,
        coltext: 4,
        type: 'number'
      },
      {
        name: 'หมายเหตุ',
        model: 'remarks',
        col: 12,
        coltext: 2,
        type: 'text'
      }
    ],
    editForm: {},
    editFormDetail: [],
    itemLists: {
      road: [],
      control: []
    },
    isConfirmEditDialog: false,
    rules: {
      required: (value) => !!value || 'กรุณากรอกข้อมูล',
      requiredAutocomplete: (v) => !!(v && v.length) || 'กรุณากรอกข้อมูล'
    },
    editFormDetailRemoveIndex: null,
    isLoading: false
  }),
  computed: {
    percentTask () {
      const percent = []
      for (const task of this.editFormDetail) {
        percent.push(
          ((task.amount_task_field / task.amount_task_contract) * 100).toFixed(
            2
          )
        )
      }
      return percent
    }
  },
  mounted () {
    this.getRefRoad()
    this.getRef('maintenance_unit')
    this.getBridgeList()
  },
  methods: {
    confirmDialog () {},
    closeConfirm () {
      this.isConfirmEditDialog = false
    },
    async deleteMaintenance () {
      const isDelete = await this.$store.dispatch(
        'maintenance/delMaintenanceContract',
        {
          id: this.editForm.id
        }
      )
      if (isDelete) {
        this.$emit('update')
      }
    },
    confirm () {
      if (this.$refs.form.validate()) {
        this.isConfirmEditDialog = true
      }
    },
    async edit () {
      this.isLoading = true
      let isEdit = null
      this.isConfirmEditDialog = false
      const editForm = { ...this.editForm }
      editForm.budget_year -= 543
      if (this.isAdd) {
        editForm.id = 0
        isEdit = await this.$store.dispatch(
          'maintenance/setMaintenanceContract',
          editForm
        )
        await this.editDetail(isEdit)
      } else {
        isEdit = await this.$store.dispatch(
          'maintenance/setMaintenanceContract',
          editForm
        )
        await this.editDetail(isEdit)
      }
      if (isEdit) {
        this.isLoading = false
        this.$emit('update')
      }
    },
    async editDetail (maintenanceContractId) {
      for (const formDetail of this.editFormDetail) {
        if (!formDetail.id) {
          formDetail.id = 0
        }
        formDetail.bridge_maintenance_contract_id = maintenanceContractId
        await this.$store.dispatch('maintenance/setMaintenanceContractDetail', {
          maintenanceContractId,
          formDetail
        })
      }
    },
    close () {
      this.$emit('close')
    },

    addListForm () {
      this.editFormDetail.push({})
    },
    removeEditFormDetail (index) {
      this.isConfirmRemoveEditFormDetailDialog = true
      this.editFormDetailRemoveIndex = index
    },
    confirmRemoveEditFormDetail () {
      this.editFormDetail.splice(this.editFormDetailRemoveIndex, 1)
      this.isConfirmRemoveEditFormDetailDialog = false
    },
    closeListConfirm () {
      this.isConfirmRemoveEditFormDetailDialog = false
    },
    updateListForm () {
      this.isConfirmRemoveEditFormDetailDialog = false
    },

    async getRefRoad () {
      const roads = await this.$store.dispatch('getRefRoad', {})
      for (const road of roads) {
        road.label = `${parseInt(road.code)}`
      }
      this.itemLists.road = roads
    },
    async getSectionPart (roadid) {
      const controls = await this.$store.dispatch('ref/getRef', {
        method: `section_part/${roadid}`
      })
      for (const control of controls) {
        control.label = `${control.code} - ${control.name}`
      }
      this.itemLists.control = controls

      const sectionPart = this.itemLists.control.find(
        (section) => section.section_part_id === this.editForm.section_part_id
      )
      if (!sectionPart) {
        delete this.editForm.section_part_id
      }
    },
    async getRef (method) {
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
    },
    clearForm () {
      this.editForm = {
        list: []
      }
      this.$refs.form.reset()
    },
    async getBridgeList () {
      const bridgeList = await this.$store.dispatch('getBridgeList', {})
      this.itemLists.bridgeList = []
      bridgeList.forEach((section) => {
        this.itemLists.bridgeList.push({
          header: `ทางหลวงหมายเลข ${parseInt(section.code_road)} - ${
            section.name
          }`
        })
        this.itemLists.bridgeList.push(...section.items)
      })
    },
    async getMaintenanceContractDetailList () {
      this.editFormDetail = await this.$store.dispatch(
        'maintenance/getMaintenanceContractDetailList',
        { id: this.editForm.id }
      )
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
