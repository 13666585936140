<template>
  <div>
    <v-dialog
      v-model="isOpenDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
      :transition="
        $vuetify.breakpoint.xs
          ? 'dialog-bottom-transition'
          : 'dialog-transition'
      "
      :max-width="width"
      persistent
    >
      <v-card>
        <div class="primary white--text">
          <v-card-title>
            {{ title }}
            <v-btn icon class="ml-auto white--text" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </div>

        <v-card-text class="ma-0 pa-0">
          <slot></slot>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="!readonly">
          <v-btn
            v-if="!isAdd"
            color="primary"
            outlined
            :disabled="isDisabledSubmit || isUpdateLoading"
            @click="isConfirmDeleteDialog = true"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
            ลบข้อมูล
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            style="width: 140px"
            class="grey--text"
            text
            :disabled="isDisabledSubmit || isUpdateLoading"
            @click="clearForm"
          >
            <v-icon>mdi-close</v-icon>
            ล้างข้อมูล
          </v-btn>
          <v-btn
            class="green white--text"
            elevation="0"
            style="width: 140px"
            :disabled="isDisabledSubmit || isUpdateLoading"
            @click="confirm"
          >
            บันทึก
            <v-progress-circular
              v-if="isUpdateLoading"
              color="white"
              :size="20"
              indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-model="isConfirmDeleteDialog"
      :dialog-title="'ยืนยันการลบข้อมูล ?'"
      :dialog-icon="'mdi-close-circle-outline'"
      :dialog-icon-color="'primary'"
      @confirm="confirmDelete()"
      @cancel="closeConfirm()"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
export default {
  components: {
    ConfirmDialog
  },
  props: {
    value: Boolean,
    title: String,
    width: {
      type: Number,
      default: () => {
        return 760
      }
    },
    isDisabledSubmit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isUpdateLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isAdd: Boolean,
    readonly: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    isOpenDialog: false,
    isConfirmDeleteDialog: false
  }),
  watch: {
    value (newVal, oldVal) {
      this.isOpenDialog = newVal
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    clearForm () {
      this.$emit('clear')
    },
    confirm () {
      this.$emit('confirm')
    },
    closeConfirm () {
      this.isConfirmDeleteDialog = false
    },
    confirmDelete () {
      this.$emit('delete')
      this.isConfirmDeleteDialog = false
    }
  }
}
</script>

<style></style>
